import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useContentModal from "../../../common/modals/useContentModal";
import PoolCandidateListItem from "./PoolCandidateListItem";
import PoolCandidatePreview from "./PoolCandidatePreview";
import PoolCandidateAdd from "./PoolCandidateAdd";
import "./PoolCandidateList.scss";
import useQueryPoolCandidatesByJobCategoryId from "./useQueryPoolCandidatesByJobCategoryId";
import PoolCandidateCreateForm from "../mutate/PoolCandidateCreateForm";
import PoolCandidateUpdateForm from "../mutate/PoolCandidateUpdateForm";

export default function PoolCandidateList(props) {

  const {jobCategoryId} = props;

  // Modal to show pool candidate creation form
  const {ContentModal: PoolCandidateCreateModal, show: showPoolCandidateCreateModal, hide: hidePoolCandidateCreateModal} = useContentModal();
  // Modal to show pool candidate details form
  const {ContentModal: PoolCandidateUpdateModal, show: showPoolCandidateUpdateModal, hide: hidePoolCandidateUpdateModal} = useContentModal("PoolCandidateUpdateModal");

  const {data: poolCandidates, loading: poolCandidatesLoading, errors: poolCandidatesErrors} = useQueryPoolCandidatesByJobCategoryId(jobCategoryId);
  // Remove null poolCandidates. This can happen after a deletion, before the list is updated
  const nonNullPoolCandidates = poolCandidates ? TypeUtils.ensureArray(poolCandidates.nodes).filter(node => node) : [];

  const onClickPoolCandidate = (poolCandidateId) => {
    showPoolCandidateUpdateModal(getPoolCandidateUpdateForm(poolCandidateId))
  }

  const onClickAddPoolCandidate = () => {
    const poolCandidateCreateForm = <PoolCandidateCreateForm onAbort={hidePoolCandidateCreateModal} onSuccess={onPoolCandidateCreated} jobCategoryId={jobCategoryId}/>;
    showPoolCandidateCreateModal(poolCandidateCreateForm);
  }

  const onPoolCandidateCreated = (poolCandidate) => {
    hidePoolCandidateCreateModal();
    showPoolCandidateUpdateModal(getPoolCandidateUpdateForm(poolCandidate._id))
  }

  const getPoolCandidateUpdateForm = (poolCandidateId) => {
    return <PoolCandidateUpdateForm onClose={hidePoolCandidateUpdateModal} poolCandidateId={poolCandidateId}/>
  }

  return (
    <>
      {PoolCandidateCreateModal} {PoolCandidateUpdateModal}
      <div className="PoolCandidateList">
        <WaitForData loading={poolCandidatesLoading} errors={poolCandidatesErrors} onLoaded={() =>
          <Row className={"poolCandidates-row"}>
            {nonNullPoolCandidates.map(poolCandidate =>
              <Col key={poolCandidate._id} className={"poolCandidate-col"}>
                <PoolCandidateListItem>
                  <PoolCandidatePreview poolCandidate={poolCandidate} onClick={() => onClickPoolCandidate(poolCandidate._id)}/>
                </PoolCandidateListItem>
              </Col>
            )}
            <Col className={"poolCandidate-col"}>
              <PoolCandidateListItem>
                <PoolCandidateAdd onClick={onClickAddPoolCandidate}/>
              </PoolCandidateListItem>
            </Col>
          </Row>
        }/>
      </div>
    </>
  );
};
