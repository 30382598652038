import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import SignInPage from "../authentication-ui/SignInPage";
import * as ROUTES from "../../constants/routes";
import AuthUserProvider from "../authentication-no-ui/AuthUserProvider";
import FirebaseProvider from "../infra-no-ui/firebase/FirebaseProvider";
import BasePage from "../common/page/BasePage";
import MenuPortal from "../common/page/MenuPortal";
import TranslationProvider from "../infra-no-ui/translation/TranslationProvider";
import FirebaseAuthProvider from "../authentication-no-ui/FirebaseAuthProvider";
import NotFoundPage from "./NotFoundPage";
import SignOutPage from "../authentication-ui/SignOutPage";
import AuthenticatedApolloProvider from "../authentication-no-ui/AuthenticatedApolloProvider";
import MissingProfileInterceptor from "../authentication-ui/MissingProfileInterceptor";
import DisconnectedModal from "../authentication-ui/DisconnectedModal";
import ResetPasswordPage from "../authentication-ui/ResetPasswordPage";
import FirebaseErrorPage from "../authentication-ui/FirebaseErrorPage";
import ClientsPage from "../reporting/clients/view/ClientsPage";
import DatePickerLocaleProvider from "./DatePickerLocalizationProvider";
import Menu from "../menu/Menu";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Loader from "../common/loading-widgets/Loader";
import ClientPage from "../reporting/clients/view/ClientPage";
import JobPage from "../reporting/jobs/view/JobPage";
import useQueryProfileForEmail from "../profiles/useQueryProfileForEmail";
import SidebarPortal from "../common/page/SidebarPortal";
import Sidebar from "../sidebar/Sidebar";
import HelpPage from "../help-page/HelpPage";
import ReportPage from "../reporting/reports/view/ReportPage";
import ReportingSettingsPage from "../reporting/settings/view/ReportingSettingsPage";
import {apolloCacheManager} from "./apolloCacheManager";
import QuestionnairesPage from "../reporting/questionnaires/view/QuestionnairesPage";
import QuestionnairePage from "../reporting/questionnaires/view/QuestionnairePage";
import TranslatedContentError from "../common/error-boundaries/TranslatedContentError";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";
import ApolloErrorLogger from "../common/error-boundaries/ApolloErrorLogger";
import ErrorTestPage from "./ErrorTestPage";
import TalentPoolsPage from "../market/pools/view/TalentPoolsPage";
import PoolJobPage from "../market/jobs/view/PoolJobPage";
import JobCategoryPage from "../market/categories/view/JobCategoryPage";
import TalentPoolPage from "../market/pools/view/TalentPoolPage";

export default function AppRouter() {

  // RENDER

  return (
    <Router>
      {/* Router must come before BasePage, because BasePage ultimately renders links */}
      {/* Display BasePage before everything else, so that we can use it to display translation and authentication errors. */}
      {/* BasePage will use portals to display menu and footer, which depend on translation and authentication that are not yet ready */}
      <BasePage>
        <Loader>
          <TranslationProvider useShowLoader={useShowLoader}>
            <ErrorBoundary component={<TranslatedContentError />}>
              <DatePickerLocaleProvider>
                <FirebaseProvider errorComponent={FirebaseErrorPage}>
                  <FirebaseAuthProvider disconnectedModal={DisconnectedModal}>
                    <AuthenticatedApolloProvider cacheManager={apolloCacheManager()}>
                      {/* At this point we can log frontend errors using Apollo because it is now available */}
                      <ErrorBoundary component={<TranslatedContentError />} logger={ApolloErrorLogger}>

                        <AuthUserProvider useQueryProfile={useQueryProfileForEmail}>
                          <MenuPortal component={<Menu/>}/>
                          <SidebarPortal component={<Sidebar/>}/>
                          <Switch>
                            {/* Put that before MissingProfileInterceptor because missing profile must not prevent from signing out */}
                            <Route exact path={ROUTES.SIGN_OUT} component={SignOutPage}/>
                            <Route>
                              {/* Don't go deeper if user is authenticated but has no profile. This happens on first sign in. */}
                              <MissingProfileInterceptor>
                                <Switch>

                                  {/* Try to keep declarations here and in routes.js in the same order, for maintainability */}

                                  <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>

                                  {/* Redirect home page with URL update so that sidebar highlights menu item */}
                                  <Route exact path={ROUTES.HOME}><Redirect to={ROUTES.REPORTS}/></Route>

                                  {/* Reports section */}
                                  <Route exact path={ROUTES.REPORTS} component={ClientsPage}/>
                                  <Route exact path={ROUTES.CLIENTS} component={ClientsPage}/>
                                  <Route exact path={ROUTES.CLIENT} component={ClientPage}/>
                                  <Route exact path={ROUTES.JOB} component={JobPage}/>
                                  <Route exact path={ROUTES.REPORT} component={ReportPage}/>

                                  {/* Questions section */}
                                  <Route exact path={ROUTES.QUESTIONNAIRES} component={QuestionnairesPage}/>
                                  <Route exact path={ROUTES.QUESTIONNAIRES_ALL} component={QuestionnairesPage}/>
                                  <Route exact path={ROUTES.QUESTIONNAIRE} component={QuestionnairePage}/>

                                  {/* Settings section */}
                                  <Route exact path={ROUTES.SETTINGS} component={ReportingSettingsPage}/>
                                  <Route exact path={ROUTES.REPORTING_SETTINGS} component={ReportingSettingsPage}/>

                                  {/* Talent market section */}
                                  <Route exact path={ROUTES.MARKET} component={TalentPoolsPage}/>
                                  <Route exact path={ROUTES.POOLS} component={TalentPoolsPage}/>
                                  <Route exact path={ROUTES.POOL} component={TalentPoolPage}/>
                                  <Route exact path={ROUTES.POOL_JOB} component={PoolJobPage}/>
                                  <Route exact path={ROUTES.JOB_CATEGORY} component={JobCategoryPage}/>

                                  <Route exact path={ROUTES.HELP} component={HelpPage}/>

                                  <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage}/>

                                  {/* Error test page */}
                                  <Route exact path={ROUTES.ERROR_TEST} component={ErrorTestPage}/>

                                  <Route path="/" component={NotFoundPage}/>
                                </Switch>
                              </MissingProfileInterceptor>
                            </Route>
                          </Switch>
                        </AuthUserProvider>
                      </ErrorBoundary>
                    </AuthenticatedApolloProvider>
                  </FirebaseAuthProvider>
                </FirebaseProvider>
              </DatePickerLocaleProvider>
            </ErrorBoundary>
          </TranslationProvider>
        </Loader>
      </BasePage>
    </Router>
  );
}
