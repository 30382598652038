import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

const WindowEnum = {
  NOT_VISIBLE: 'NOT_VISIBLE',
  ASK_CONFIRMATION: 'ASK_CONFIRMATION',
  CONFIRMATION_IN_PROGRESS: 'CONFIRMATION_IN_PROGRESS',
  OPERATION_FAILED: 'OPERATION_FAILED',
  OPERATION_SUCCEEDED: 'OPERATION_SUCCEEDED',
};

const ActionEnum = {
  ASK_CONFIRMATION: 'ASK_CONFIRMATION',
  LAUNCH_OPERATION: 'LAUNCH_OPERATION',
  DISPLAY_ERROR: 'DISPLAY_ERROR',
  DISPLAY_SUCCESS: 'DISPLAY_SUCCESS',
  DISMISS: 'DISMISS'
}

/**
 * Display a modal to give a user feedback about the completion of an operation.
 * @param successMsg Message to display when the operation completes with success (leave undefined for no success modal)
 * @param errorMsg Error message to display when the operation completes with errors
 * @param onSuccess Callback for additional things to do when the operation completes with success. Called with the result of the operation.
 * @param confirmMsg Confirmation message to display before launching the operation (leave undefined for no confirmation)
 * @returns {{FeedbackModal: (JSX.Element|null), launch: ((function(*=): void)|*)}} Modal to display and callback to launch an operation (callback takes a promise as a param)
 */
export default function useFeedbackModal(successMsg, errorMsg, onSuccess, confirmMsg) {

  const initialState = {
    window: WindowEnum.NOT_VISIBLE,
    error: null,
    operationPromise: null,
    operationResult: null
  };

  // We use a reducer because many state variables have to change together and we want to avoid
  // rendering glitches
  const reducer = (state, action) => {
    switch (action.type) {
      case ActionEnum.ASK_CONFIRMATION:
        return {
          window: WindowEnum.ASK_CONFIRMATION,
          operationPromise: action.operationPromise
        }
      case ActionEnum.LAUNCH_OPERATION:
        return {
          window: confirmMsg ? WindowEnum.CONFIRMATION_IN_PROGRESS : WindowEnum.NOT_VISIBLE,
          operationPromise: action.operationPromise
        };
      case ActionEnum.DISPLAY_ERROR:
        return {
          window: WindowEnum.OPERATION_FAILED,
          error: action.error,
        };
      case ActionEnum.DISPLAY_SUCCESS:
        return {
          window: WindowEnum.OPERATION_SUCCEEDED,
          operationResult: action.operationResult,
          error: null
        };
      case ActionEnum.DISMISS:
        return {
          window: WindowEnum.NOT_VISIBLE,
          error: null,
          operationPromise: null,
        };
      default:
        throw new Error("Unsupported action in useFeedbackModal reducer");
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const acknowledge = React.useCallback((operationResult) => {
    dispatch({type: ActionEnum.DISMISS});
    if (onSuccess)
      onSuccess(operationResult);
  }, [onSuccess]);

  /**
   * Launch the operation. Operation must be a promise.
   * If no confirmation is required, there is nothing to display until the operation resolves.
   * If a confirmation is required, leave the confirmation box open while waiting for the operation to resolve.
   * @type {(function(*): void)|*}
   */
  const launchOperation = React.useCallback((operationPromise) => {
    // Must use a useCallback because we don't want the callback to change on every render, thus re-rendering parent consumers
    dispatch({type: ActionEnum.LAUNCH_OPERATION, operationPromise});
    operationPromise()
      .then((result) => {
        // If there is a success message, display it, otherwise close everything
        if (successMsg)
          dispatch({type: ActionEnum.DISPLAY_SUCCESS, operationResult: result})
        else
          acknowledge(result);
      })
      .catch(error => dispatch({type: ActionEnum.DISPLAY_ERROR, error}));
  }, [acknowledge, successMsg]);

  /**
   * Launch the operation, but check if a confirmation is required before actually launching the operation.
   * Operation must be a promise.
   * If no confirmation is required, launch the operation without displaying anything.
   * If a confirmation is required, display the confirmation box and wait for the user confirmation.
   * @type {(function(*=): void)|*}
   */
  const launch = React.useCallback((operationPromise) => {
    // Must use useCallback because we don't want the callback to change on every render, thus re-rendering parent consumers
    if (confirmMsg) {
      dispatch({type: ActionEnum.ASK_CONFIRMATION, operationPromise});
    }
    else {
      launchOperation(operationPromise);
    }
  }, [launchOperation, confirmMsg]);

  /**
   * Dismiss the dialog, operation was not performed or failed
   * @type {(function(): void)|*}
   */
  const abort = React.useCallback(() => {
    dispatch({type: ActionEnum.DISMISS});
  }, []);

  /**
   * Return the dialog to display (none, ask confirmation, confirmation given but waiting for operation to
   * complete, success, error)
   * @returns {JSX.Element|null}
   */
  const selectModal = () => {
    switch(state.window) {
      case WindowEnum.NOT_VISIBLE:
        return null;
      case WindowEnum.ASK_CONFIRMATION:
        return <ConfirmationModal show onDismiss={abort} onConfirm={() => launchOperation(state.operationPromise)}>{confirmMsg ? confirmMsg : null}</ConfirmationModal>
      case WindowEnum.CONFIRMATION_IN_PROGRESS:
        return <ConfirmationModal show onDismiss={abort} loading>{confirmMsg ? confirmMsg : null}</ConfirmationModal>
      case WindowEnum.OPERATION_FAILED:
        return <ErrorModal show onDismiss={abort}>{errorMsg ? errorMsg : null}</ErrorModal>
      case WindowEnum.OPERATION_SUCCEEDED:
        return <SuccessModal show onDismiss={() => acknowledge(state.operationResult)}>{successMsg ? successMsg : null}</SuccessModal>
      default:
        return null;
    }
  }

  /**
   * Return the modal to display and a launch callback.
   */
  return {
    FeedbackModal: selectModal(),
    launch // Takes one param: operation promise
  }
};

