import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationClientDelete from "./useMutationClientDelete";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import ActionLink from "../../../common/widgets/ActionLink";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";

export default function ClientDeleteButton(props) {

  const {clientId, onCompleted} = props;

  const deleteClient = () => {
    return mutate({
      variables: {
        id: clientId,
      },
    });
  }

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deleteClient);
  }

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientDeleteButton");

  const {mutate, loading: deleteLoading, errors: deleteErrors} = useMutationClientDelete();

  const successMsg = <p>{t("reporting:clients_delete_success_msg")}</p>;
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmationMsg = <p>{t("reporting:clients_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal(successMsg, errorMsg, onCompleted, confirmationMsg);

  return (
    <>
      {FeedbackModal}
      <ActionLink onClick={onClickDelete} loading={deleteLoading}>{t("reporting:clients_details_delete_action")}</ActionLink>
    </>
  );
}
