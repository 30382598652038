import React from "react";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationPoolJobDelete from "./useMutationPoolJobDelete";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import ActionIcon from "../../../common/widgets/ActionIcon";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import useTranslationMarket from "../../useTranslationMarket";

export default function PoolJobDeleteButton(props) {

  const {poolJobId, onCompleted} = props;

  const deletePoolJob = () => {
    return mutate({
      variables: {
        id: poolJobId,
      },
    });
  }

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deletePoolJob);
  }

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobDeleteButton");

  const {mutate, loading: deleteLoading, errors: deleteErrors} = useMutationPoolJobDelete();

  // Page refreshes rapidly after deletion, the success msg just creates a flicker, so don't display feedback
  //const successMsg = <p>{t("market:pool_jobs_delete_success_msg")}</p>;
  const successMsg = undefined;
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmationMsg = <p>{t("market:pool_jobs_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal(successMsg, errorMsg, onCompleted, confirmationMsg);

  return (
    <>
      {FeedbackModal}
      <ActionIcon onClick={onClickDelete} loading={deleteLoading} icon={DeleteIcon}>{t("market:pool_jobs_details_delete_action")}</ActionIcon>
    </>
  );
}
