import 'react-app-polyfill/ie11'; // Must be first line of src/index.js. See module doc.
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import "./scss/globals.scss";

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(<App/>, document.getElementById("root"));
