import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionDiv from "../../../common/widgets/ActionDiv";
import PoolJobDeleteButton from "../mutate/PoolJobDeleteButton";
import "./PoolJobPreview.scss";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";

export default function PoolJobPreview(props) {

  const {poolJob, onClick} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobPreview");

  return (
    <div className="PoolJobPreview">
      <Row className={"delete-row"}>
        <Col className={"delete-col"}>
          <PoolJobDeleteButton poolJobId={poolJob._id}/>
        </Col>
      </Row>
      <Row className={"content-row"}>
        <Col className={"content-col"}>
          <Row className={"name-row"}>
            <Col className={"name-col"}>
              <ActionDiv onClick={() => onClick(poolJob._id)} className={"name"}>
                {poolJob.name}
              </ActionDiv>
            </Col>
          </Row>
          <Row className={"category-row"}>
            <Col className={"category-col"}>
              {t("market:pool_jobs_preview_category_count", {count: StringUtils.nullToZero(poolJob.categoryCount)})}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
