import React from "react";
import EditIcon from "@material-ui/icons/EditSharp";
import StringUtils from "../../../utils/StringUtils";
import "./InlineEditBase.scss";
import ActionIcon from "./ActionIcon";
import ActionDiv from "./ActionDiv";
import usePreviousState from "../../infra-no-ui/react-helpers/usePreviousState";
import InlineEditSubmitStatus from "./InlineEditSubmitStatus";

/**
 * Form control that displays a value as read only until we click the Edit icon next to the label.
 * The value then becomes editable through a component passed as parameter. The component must
 * implements the behaviour for properties placeholder, value, onSubmit, validate, onCancel and format.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditBase(props) {

  const {
    value: initialValue, // Value to display when the component is first rendered
    validate, // Validation callback
    onSubmit, // Callback to call when value is accepted. Must return a promise.
    openEditing, // Display the edit component right away (no need to click the edit icon)
    editOnly, // Open in editing mode and stay in editing mode
    onEnterEdit, // Callback to call when entering edit mode. Must return a promise.
    className,
    placeholder, // Text to display when there are no value
    viewAs, // Html tag to surround value with when in edit mode, such as h1 if value is a title
    loading, // The initial value is still loading
    EditComponent, // Component to display when user clicks the edit icon, to modify the value
    formatValue, // Callback to format the raw value before displaying it
    actionIcon, // Icon the user clicks on to edit the value
    ViewComponent, // Component to display when in view mode
    multilineView, // Set to true if content should span multiple lines when in view mode
    onCancel, // Perform additional actions when user clicks the cancel edition button
    ...otherProps // Other props to pass to ViewComponent and EditComponent (beware of collisions)
  } = props;

  const [isEditing, setIsEditing] = React.useState(openEditing || editOnly);

  const [submitStatus, setSubmitStatus] = React.useState(InlineEditSubmitStatus.READY);
  const previousSubmitStatus = usePreviousState(submitStatus);

  // Keep track of the value that is submitted for modification, so that we can display it until the modification is
  // successful and we get a new initial value (that may come from a cache update after a modification)
  const [value, setValue] = React.useState(initialValue);

  // A re-render of the component with another initial value must reset the working value
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onLocalSubmit = value => {
    setSubmitStatus(InlineEditSubmitStatus.SUBMITTING);

    // Remember the submitted value until the modification callback updates the cache and re-render the component with
    // the updated value
    setValue(value);
    // Perform the modification callback
    onSubmit(value)
      .then(() => setSubmitStatus(InlineEditSubmitStatus.SUCCESS))
      .catch(error => setSubmitStatus(InlineEditSubmitStatus.FAILURE));
  }

  React.useEffect(() => {
    // If submit status has changed, change read/edit mode
    if (previousSubmitStatus !== submitStatus) {
      // If now submitting, revert to read mode unless we want to stay in edit mode
      if (!editOnly && submitStatus === InlineEditSubmitStatus.SUBMITTING)
        setIsEditing(false);
    }
  }, [previousSubmitStatus, submitStatus, editOnly]);

  const onLocalEnterEdit = () => {
    // If no onEnterEdit callback defined, define a trivial one
    const onEnterEditCallback = onEnterEdit ? onEnterEdit : () => Promise.resolve();
    // Perform callback before entering edit mode
    onEnterEditCallback().then(() => {
      setIsEditing(true)
    });
  }

  const onLocalCancel = () => {
    if (!editOnly)
      setIsEditing(false);

    setValue(initialValue);
    setSubmitStatus(InlineEditSubmitStatus.READY);

    if (onCancel) {
      onCancel();
    }
  }

  const formattedValue = formatValue ? formatValue(value) : value;

  const RealViewComponent = ViewComponent ? ViewComponent : DefaultViewComponent;
  const newClassName = ["InlineEditBase", multilineView ? "multiline-view" : "", className].join(" ");

  return (
    <div className={newClassName}>
      {!isEditing &&
      <RealViewComponent
        value={formattedValue}
        onEnterEdit={onLocalEnterEdit}
        viewAs={viewAs}
        loading={loading}
        actionIcon={actionIcon}
        placeholder={placeholder}
        submitStatus={submitStatus}
        {...otherProps}
      />}
      {isEditing &&
      <div className={"edit"}>
        <EditComponent
          placeholder={placeholder}
          value={value}
          onSubmit={onLocalSubmit}
          validate={validate}
          onCancel={onLocalCancel}
          viewAs={viewAs}
          submitStatus={submitStatus}
          {...otherProps}
        />
      </div>
      }
    </div>
  );
};

function DefaultViewComponent(props) {
  const {value, onEnterEdit, viewAs: ViewAs, loading, actionIcon, placeholder, submitStatus} = props;

  const onClickEdit = event => {
    event.preventDefault();
    onEnterEdit();
  }

  const hasValue = !StringUtils.isNullOrEmpty(value);
  const displayValue = hasValue ? value : placeholder;

  const valueAs = ViewAs ? <ViewAs>{displayValue}</ViewAs> : displayValue;
  const editIcon = actionIcon ? actionIcon : EditIcon;
  const hasValueClassName = hasValue ? "has-value" : "no-value";

  const errorClass = submitStatus === InlineEditSubmitStatus.FAILURE ? "error" : "";

  return (
    <div className={"view " + errorClass}>
      <div className={["value", hasValueClassName].join(" ")}>
        <ActionDiv onClick={onClickEdit}>{valueAs}</ActionDiv>
      </div>
      <div className={"action " + errorClass}>
        <ActionIcon onClick={onClickEdit} icon={editIcon} loading={loading || submitStatus === InlineEditSubmitStatus.SUBMITTING}/>
      </div>
    </div>
  );
}
