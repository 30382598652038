import React from 'react';
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import HorizontalLoader from "../../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import "./ReportByJobCount.scss";
import useQueryReportSearchCount from "../../reports/search/useQueryReportSearchCount";

function ReportByJobCount(props) {

  const {jobId} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportByJobCount");


  const {data: count, loading, errors} = useQueryReportSearchCount({jobId});

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() =>
          <ReportProblemOutlined className="LoadingError"/>
        }
        onLoaded={() =>
          // t can use the _plural key because we provide a "count" and a number
          t("reporting:jobs_search_report_count", {
            count: StringUtils.nullToZero(count)
          })
        }
      />
    </HorizontalLoader>
  );
}

export default ReportByJobCount;
