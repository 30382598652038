import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PoolCandidateInlineEditInputText from "./PoolCandidateInlineEditInputText";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryPoolCandidateById from "../view/useQueryPoolCandidateById";
import StringUtils from "../../../../utils/StringUtils";
import PoolCandidateInlineEditInputTextArray from "./PoolCandidateInlineEditInputTextArray";
import ActionLink from "../../../common/widgets/ActionLink";
import useContentModal from "../../../common/modals/useContentModal";
import "./PoolCandidateUpdateForm.scss";
import ResumeImage from "../../../../img/resume-download.png";
import Image from "react-bootstrap/Image";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import PoolCandidateInlineEditInputWysiwyg from "./PoolCandidateInlineEditInputWysiwyg";
import PoolCandidateResumeDownloadLink from "../view/PoolCandidateResumeDownloadLink";
import PoolCandidateResumeUpdate from "./PoolCandidateResumeUpdate";
import PoolCandidateResumeDeleteLink from "./PoolCandidateResumeDeleteLink";
import PoolCandidateInlineEditInputBoolean from "./PoolCandidateInlineEditInputBoolean";

export default function PoolCandidateUpdateForm(props) {

  const {poolCandidateId, onClose} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolCandidateUpdateForm");

  // Modal to show resume upload form
  const {ContentModal: PoolCandidateResumeUpdateModal, show: showPoolCandidateResumeUpdateModal, hide: hidePoolCandidateResumeUpdateModal} = useContentModal();

  const {data: poolCandidate, loading: poolCandidateLoading, errors: poolCandidateErrors} = useQueryPoolCandidateById(poolCandidateId);

  const hasResume = poolCandidate && poolCandidate.resumeFile && !StringUtils.isNullOrEmpty(poolCandidate.resumeFile.filename);
  const viewAction = t("market:pool_candidates_details_resume_view_action");
  const modifyAction = t("market:pool_candidates_details_resume_modify_action");
  const deleteAction = t("market:pool_candidates_details_resume_delete_action");

  const validateName = (name) => {
    if (StringUtils.isNullOrEmpty(name))
      return t("market:pool_candidates_details_name_required_error");
  }

  const onClickUpdateResume = () => {
    showPoolCandidateResumeUpdateModal(
      <PoolCandidateResumeUpdate candidateId={poolCandidateId} onSuccess={hidePoolCandidateResumeUpdateModal} onAbort={hidePoolCandidateResumeUpdateModal}/>);
  }

  return (
    <>
      {PoolCandidateResumeUpdateModal}
      <WaitForData loading={poolCandidateLoading} errors={poolCandidateErrors} onLoaded={() =>
        <div className={"PoolCandidateUpdateForm form-with-rows"}>
          <Row className={"first-row"}>
            <Col className="candidate-col">
              <Row>
                <Col>
                  {t("market:pool_candidates_details_number_label", {number: poolCandidate.number})}
                </Col>
                <Col className={"published-col"}>
                  <PoolCandidateInlineEditInputBoolean poolCandidate={poolCandidate} property={"published"} placeholder={t("market:pool_candidates_details_published_placeholder")}/>
                </Col>
              </Row>
              <Row className={"name-row"}>
                <Col className="name-col">
                  <PoolCandidateInlineEditInputText
                    poolCandidate={poolCandidate}
                    validate={validateName}
                    placeholder={t("market:pool_candidates_details_name_placeholder")}
                    property={"name"}
                    viewAs={"h1"}
                  />
                </Col>
              </Row>
              <Row className={"contact-row"}>
                <Col className={"contact-col"}>
                  <PoolCandidateInlineEditInputTextArray poolCandidate={poolCandidate} property={"contactLines"} placeholder={t("market:pool_candidates_details_contacts_placeholder")}/>
                </Col>
              </Row>
            </Col>
            <Col className="resume-col">
              <Row className={"resume-row"}>
                <Col className={"resume-actions-col"}>
                  <Row>
                    <Col>
                      {/* Do not disable link, contrast with background is not high enough */}
                      {!hasResume && viewAction}
                      {hasResume && <PoolCandidateResumeDownloadLink candidateId={poolCandidate._id}>{viewAction}</PoolCandidateResumeDownloadLink>}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ActionLink onClick={onClickUpdateResume}>{modifyAction}</ActionLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/* Do not disable link, contrast with background is not high enough */}
                      {!hasResume && deleteAction}
                      {hasResume && <PoolCandidateResumeDeleteLink candidateId={poolCandidate._id}>{deleteAction}</PoolCandidateResumeDeleteLink>}
                    </Col>
                  </Row>
                </Col>
                <Col className={"resume-icon-col"}>
                  <Image src={ResumeImage}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <PoolCandidateInlineEditInputWysiwyg
                className={"note-input"}
                poolCandidate={poolCandidate}
                placeholder={t("market:pool_candidates_details_note_placeholder")}
                property={"note"}
              />
            </Col>
          </Row>
          <Row>
            <Col className="form-actions">
              <ActionButtonWithIconCancel onClick={onClose}>{t("market:pool_candidates_details_close_action")}</ActionButtonWithIconCancel>
            </Col>
          </Row>
        </div>
      }/>
    </>
  )
}
