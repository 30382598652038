import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import {useHistory} from "react-router-dom";
import "./ClientSearchResult.scss";
import ActionButton from "../../../common/widgets/ActionButton";
import JobByClientCount from "./JobByClientCount";

export default function ClientSearchResult(props) {

  const {item: client} = props;

  const history = useHistory();

  function onClickClient(event, clientId) {
    event.preventDefault();
    history.push(RouterUtils.injectParamsInRoute(ROUTES.CLIENT, {clientId}))
  }

  return (
    <ActionButton as={"div"} variant="outline-primary" onClick={event => onClickClient(event, client._id)} className={"ClientSearchResult"}>
      <Row>
        <Col className={"name-col"}>
          {client.name}
        </Col>
        <Col className={"job-col"}>
          <JobByClientCount clientId={client._id}/>
        </Col>
      </Row>
    </ActionButton>
  );
};
