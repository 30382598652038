import React from "react";
import TypeUtils from "../../../../utils/TypeUtils";
import PoolCandidateInlineEditInputText from "./PoolCandidateInlineEditInputText";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionLink from "../../../common/widgets/ActionLink";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useTranslationMarket from "../../useTranslationMarket";
import "./PoolCandidateInlineEditInputTextArray.scss";

/**
 * Generic component for all pool candidate editable fields that are arrays of text values
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PoolCandidateInlineEditInputTextArray(props) {

  const {poolCandidate, property, ...otherProps} = props;
  // Define this constant so that we can use it in the useMemo dependency otherwise compiler will complain with "poolCandidate[property]"
  const initialValues = poolCandidate[property];

  // Add an empty value at the end and override the ViewComponent of this last input box so that it displays the "Add" prompt.
  // It is important that the last empty input had its own React key, otherwise it will not unmount after the addition of a value and it will
  // keep its state, resulting in the new empty input to display the previously added value when adding another value.
  const values = React.useMemo(() => [...TypeUtils.ensureArray(initialValues), ""], [initialValues]);

  return (
    <div className={"PoolCandidateInlineEditInputTextArray"}>
      {values.map((value, index) =>
        <Row key={index}>
          <Col>
            <PoolCandidateInlineEditInputText
              poolCandidate={poolCandidate}
              property={property}
              index={index}
              value={value}
              ViewComponent={index === values.length - 1 ? AddItemViewComponent : undefined}
              {...otherProps}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

function AddItemViewComponent(props) {
  // Gets the same props as the DefaultViewComponent used by InlineEditBase, but we don't use them all
  const {onEnterEdit} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "AddItemViewComponent");

  const onClickLink = event => {
    event.preventDefault();
    onEnterEdit();
  }

  return (
    <div className={"view"}>
      <ActionLink onClick={onClickLink}><span className={"action-label"}>{t("market:pool_candidates_details_contacts_add_action")}</span></ActionLink>
    </div>
  );

}
