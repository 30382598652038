import React from "react";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import ActionButtonWithIcon from "../../common/widgets/ActionButtonWithIcon";

/**
 * ActionButton with an arrow icon at the end of text.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionButtonWithIconGo(props) {
  return <ActionButtonWithIcon Icon={ArrowIcon} {...props}/>
};
