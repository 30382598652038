import React from "react";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import ProfileCreationForm from "./ProfileCreationForm";
import useContentModal from "../common/modals/useContentModal";

export default function MissingProfileInterceptor(props) {

  const {children} = props;
  const authUser = useAuthUser();

  // Modal to show profile creation form
  const {ContentModal: ProfileCreateModal, show: showProfileCreateModal, hide: hideProfileCreateModal} = useContentModal();

  // If user is authenticated but has not profile, then ask for the creation of a profile
  React.useEffect(() => {
    if (authUser.authenticated && !authUser.profile) {
      showProfileCreateModal(<ProfileCreationForm/>);
    }
    return hideProfileCreateModal;
  }, [authUser, showProfileCreateModal, hideProfileCreateModal])

  return (
    <>
      {ProfileCreateModal}
      {children ? children : null}
    </>
  );
}
