import React from "react";
import {FormControl, InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import StringUtils from "../../../utils/StringUtils";
import Button from "react-bootstrap/Button";
import "./InlineEditInputText.scss";
import CancelIcon from "@material-ui/icons/Clear";
import ConfirmIcon from "@material-ui/icons/Done";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InlineEditBase from "./InlineEditBase";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents";

/**
 * Input box that display the value as read only until we click the Edit icon next to the label.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditInputText(props) {

  // Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
  const WithOnBlurInputTextEditComponent = withOnBlur()(InputTextEditComponent);

  return <InlineEditBase {...props} EditComponent={WithOnBlurInputTextEditComponent}/>
};

function InputTextEditComponent(props) {

  const {viewAs, placeholder, value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener} = props;
  const focusRef = React.useRef();

  const formatOutput = React.useCallback(output => {
    return StringUtils.nullToEmpty(output).trim();
  }, []);

  const {currentValue, submit, cancel, changeValue, validationError} = useInlineEditInputEvents(
    value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener, focusRef,null, formatOutput
  );

  const hasError = !StringUtils.isNullOrEmpty(validationError);

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  // User types a letter in the input box
  const onChange = event => {
    changeValue(event.target.value);
    // Don't call validate here, because the current value might not be rendered yet.
  }

  // Cancel button is clicked
  const onClickCancel = event => {
    event.preventDefault();
    cancel();
  }

  return (
    <Form onSubmit={onLocalSubmit} className={"InputTextEditComponent"} autoComplete={"off"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <InputGroup>
            <FormControl
              ref={focusRef}
              type="text"
              value={currentValue}
              onChange={onChange}
              placeholder={placeholder}
              size={viewAs === "h1" ? "lg" : "text"}
            />
            <Button variant="success" type={"submit"} disabled={hasError}><ConfirmIcon/></Button>
            <Button variant="danger" onClick={onClickCancel}><CancelIcon/></Button>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col className={"error-col"}>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </Form>
  );
}
