import React from "react";
import { List, arrayMove } from 'react-movable';
import TypeUtils from "../../../../utils/TypeUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ActionLink from "../../../common/widgets/ActionLink";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import useMutationQuestionnaireUpdate from "../mutate/useMutationQuestionnaireUpdate";
import "./QuestionList.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuestionDetails from "./QuestionDetails";
import QuestionDetailsList from "./QuestionDetailsList";
import QuestionDetailsListItem from "./QuestionDetailsListItem";
import usePreviousState from "../../../infra-no-ui/react-helpers/usePreviousState";
import scrollToComponent from "../../../common/page/scrollToComponent.js";

export default function QuestionList(props) {

  const {questionnaire} = props;
  const questions = questionnaire.questions;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionList");

  const {mutate, errors: mutationErrors} = useMutationQuestionnaireUpdate();
  const errorMsg = useServerErrorFormatter(mutationErrors);
  const {launch, FeedbackModal} = useFeedbackModal(undefined, errorMsg);

  const [showAddComponent, setShowAddComponent] = React.useState(false);
  const [isReordering, setIsReordering] = React.useState(false);

  // Put a ref on a dummy html element so that we can scroll the bottom of the list into view when and after we add a new question
  const listBottomRef = React.useRef(null)
  const previousShowAddComponent = usePreviousState(showAddComponent);

  const onClickAdd = (event => {
    event.preventDefault();
    setShowAddComponent(true);
  })

  // Scroll to the bottom when we click to add a question so that we see the new question box.
  // Do it in an effect so that all elements are at their place before moving the focus at the bottom of the list
  React.useLayoutEffect(() => {
    if (previousShowAddComponent === !showAddComponent) {
      scrollToComponent(listBottomRef.current, {duration: 100});
    }
  }, [previousShowAddComponent, showAddComponent]);

  const onQuestionAdded = () => {
    setShowAddComponent(false);
  }

  const [items, setItems] = React.useState(TypeUtils.ensureArray(questions));

  // Callback called by the draggable list when a question is moved
  const onQuestionMoved = React.useCallback(({oldIndex, newIndex}) => {

    // Move item locally so that the feedback is immediate
    setItems(arrayMove(items, oldIndex, newIndex))

    // Persist the reordering. Note that when the answer is received, the list is redrawn
    const newQuestions = arrayMove(questions, oldIndex, newIndex);

    const moveQuestions = () => {
      return mutate({
        variables: {
          id: questionnaire._id,
          questionIds: newQuestions.map(q => q._id)
        }
      })
    }

    launch(moveQuestions);
  }, [mutate, questionnaire._id, items, launch, questions]);

  // When a question is added (or modified elsewhere), refresh the list
  React.useEffect(() => {
    setItems(questions);
  }, [questions]);

  return (
    <>
      {FeedbackModal}
      <div className={"QuestionList"}>
        <Row>
          <Col>
            {isReordering ?
              <List
                values={items}
                onChange={onQuestionMoved}
                renderList={({children, props}) =>
                  <QuestionDetailsList {...props}>{children}</QuestionDetailsList>}
                renderItem={({value, isDragged, props}) =>
                  <QuestionDetailsListItem question={value} isDraggable isDragged={isDragged} questionnaireId={questionnaire._id} {...props}/>}
              /> :
              <QuestionDetailsList>
                {items.map(item =>
                  <QuestionDetailsListItem key={item._id} question={item} questionnaireId={questionnaire._id}/>
                )}
              </QuestionDetailsList>
            }
          </Col>
        </Row>
        <Row>
          <Col className={"action-col"}>
            <div ref={listBottomRef}>
              {showAddComponent ?
                <EmptyQuestion questionnaireId={questionnaire._id} onSuccess={onQuestionAdded}/> :
                <>
                  {isReordering ?
                    <ActionLink onClick={() => setIsReordering(false)}>{t("reporting:questions_reorder_stop_action")}</ActionLink> :
                    <>
                      <ActionLink onClick={onClickAdd}>{t("reporting:questions_create_action")}</ActionLink>
                      {items.length > 0 &&
                      <ActionLink
                        onClick={() => setIsReordering(true)}>{t("reporting:questions_reorder_start_action")}</ActionLink>
                      }
                    </>
                  }
                </>
              }
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

function EmptyQuestion(props) {
  const {questionnaireId, onSuccess} = props;

  return <QuestionDetails questionnaireId={questionnaireId} onCompleted={onSuccess}/>
}
