import {useLocation, useHistory} from "react-router-dom";

/**
 * Redirect to the referrer location specified in the state of the current location, typically after
 * Sign In or Sign Out.
 *
 * Redirect to HOME (passed as a prop) if there is no referrer or if current location is the same as the referrer
 * (in case referrer is Sign In or Sign Out).
 */
export default function useReferrer(fallback) {

  const redirectToReferrer = () => {
    history.push(referrer);
  }

  const location = useLocation();
  const history = useHistory();

  const referrerPathname = (location.state && location.state.referrer ?
    location.state.referrer.pathname : null);
  const referrerSearch = (location.state && location.state.referrer ?
    location.state.referrer.search : null);
  const locationAsString = location.pathname + location.search;
  const referrerAsString = referrerPathname + referrerSearch;
  const referrer = (referrerPathname && locationAsString !== referrerAsString ? location.state.referrer : fallback);

  return {referrer, redirectToReferrer};
}
