import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ReportingSettingsInlineEditInputWysiwyg from "../mutate/ReportingSettingsInlineEditInputWysiwyg";
import "./ReportingSettingsBlock.scss";
import HtmlUtils from "../../../../utils/HtmlUtils";

export default function ReportingSettingsBlock(props) {

  const {profileId, settings} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading);

  return (
    <>
      <div className="ReportingSettingsBlock form-with-rows">
        <Row>
          <Col className={"form-label"}>
            {t("reporting:settings_default_notification_message_label")}
          </Col>
        </Row>
        <Row>
          <Col>
            <ReportingSettingsInlineEditInputWysiwyg profileId={profileId} settings={settings} placeholder={t("reporting:settings_default_notification_message_placeholder")} property={"defaultNotificationMessage"}/>
          </Col>
        </Row>
        <Row>
          <Col className={"form-info"}>
            {HtmlUtils.htmlToComponents(t("reporting:emails_create_link_msg"))}
          </Col>
        </Row>
        <Row>
          <Col className={"form-info"}>
            {HtmlUtils.htmlToComponents(t("reporting:emails_create_from_name_msg"))}
          </Col>
        </Row>
      </div>
    </>
  );
}
