import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryReportNames from "./useQueryReportNames";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import SearchBox from "../../common/SearchBox";

export default function ReportSearchBox(props) {

  const {jobId, clientId} = props;

  const {data: results, loading: reportsLoading, errors: reportsErrors} = useQueryReportNames(jobId);

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportSearchBox");
  const history = useHistory();

  const useQueryOptions = () => {
    return {
      options: results ? results.nodes : [],
      loading: reportsLoading,
      errors: reportsErrors
    }
  }

  const onSelectOption = (option) => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.REPORT, {
      clientId: clientId,
      jobId: jobId,
      reportId: option._id
    }))}

  const errorMsg = t("reporting:reports_search_filter_error");
  const emptyMsg =  t("reporting:reports_search_filter_empty");
  const placeholder = t("reporting:reports_search_filter_placeholder");

  return (
    <SearchBox
      className = {"ReportSearchBox"}
      useQueryOptions = {useQueryOptions}
      onSelectOption = {onSelectOption}
      errorMsg = {errorMsg}
      emptyMsg = {emptyMsg}
      placeholder = {placeholder}
      labelKey = {"name"}
    />
  );
}
