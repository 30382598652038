import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AnswerInlineEditInputTextArea from "../../answers/mutate/AnswerInlineEditInputTextArea";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./CandidateAnswer.scss";

export default function CandidateAnswer(props) {

  const {candidate, question, answer} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "CandidateAnswer");

  return (
    <div className="CandidateAnswer">
      <Row>
        <Col className={"question-col"}>
          {question.text}
        </Col>
      </Row>
      <Row>
        <Col className={"answer-col"}>
          <AnswerInlineEditInputTextArea
            answer={answer}
            property={"text"}
            placeholder={t("reporting:answers_details_text_placeholder")}
            candidateId={candidate._id}
            questionId={question._id}
          />
        </Col>
      </Row>
    </div>
  );
};
