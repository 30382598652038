import React from "react";
import Form from "react-bootstrap/Form";
import StringUtils from "../../../utils/StringUtils";
import Button from "react-bootstrap/Button";
import "./InlineEditWysiwyg.scss";
import CancelIcon from "@material-ui/icons/Clear";
import ConfirmIcon from "@material-ui/icons/Done";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InlineEditBase from "./InlineEditBase";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw} from 'draft-js';
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import {stateFromHTML} from 'draft-js-import-html';
import HtmlUtils from "../../../utils/HtmlUtils";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents";
import WysiwygEditor from "./WysiwygEditor";

/**
 * Div that displays the rendered html as read only until we click the Edit icon next to the note.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditWysiwyg(props) {

  // Format an html string into React elements
  const formatHtml = React.useCallback((html) => {
    return HtmlUtils.htmlToComponents(html);
  }, []);

  // Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
  const WithOnBlurWysiwygEditComponent = withOnBlur()(WysiwygEditComponent);

  return <InlineEditBase EditComponent={WithOnBlurWysiwygEditComponent} formatValue={formatHtml} multilineView {...props} />
};

function WysiwygEditComponent(props) {

  const {
    placeholder,
    value, // Initial value, before formatting for Wysiwyg component
    onSubmit, // Callback to call when user clicks Submit or exit the field. Must be a promise
    validate, // Validation callback
    onCancel, // Callback to call when user clicks Cancel or exit the field with Escape. Must be a promise.
    setBlurListener, // "react-onblur" callback to manage blurring event on the whole component, including subcomponents
    unsetBlurListener // "react-onblur" callback to stop managing blurring events
  } = props;

  const focusRef = React.useRef();

  const formatInput = React.useCallback((input) => {
     return EditorState.createWithContent(stateFromHTML(input));
  }, []);

  const formatOutput = React.useCallback(output => {
    // Wysiwyg always outputs "<p></p>" when user deletes editor content. Reconstruct the html nodes from the string,
    // then get text (not tags), and if empty then return nothing, so that the editor knows it should display the
    // placeholder (because its content is now really empty).
    if (StringUtils.isNullOrEmpty(HtmlUtils.getNodeText(HtmlUtils.htmlToComponents(draftToHtml(convertToRaw(output.getCurrentContent())))).trim())) {
      return "";
    }

    return draftToHtml(convertToRaw(output.getCurrentContent()));
  }, []);

  const {currentValue, submit, cancel, changeValue, validationError} = useInlineEditInputEvents(
    value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener, focusRef, formatInput, formatOutput
  );
  const hasError = !StringUtils.isNullOrEmpty(validationError);

  const {getCurrentLanguageCode} = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  // User makes one change in the wysiwyg content
  const onChange = editorState => {
    changeValue(editorState);
    // Don't call validate here, because the current value might not be rendered yet.
  }

  // Cancel button is clicked
  const onClickCancel = event => {
    event.preventDefault();
    cancel();
  }

  return (
    <Form onSubmit={onLocalSubmit} className={"WysiwygEditComponent"} autoComplete={"off"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <WysiwygEditor
            setEditorRef={(ref) => (focusRef.current = ref)} /*Get a ref to the editor so that we can focus on it */
            value={currentValue}
            onChange={onChange}
            placeholder={placeholder}
            languageCode={currentLanguageCode}
          />
        </Col>
      </Row>
      <Row className={"button-row"}>
        <Col className={"button-col"}>
          <Button variant="success" type={"submit"} disabled={hasError}><ConfirmIcon/></Button>
          <Button variant="danger" onClick={onClickCancel}><CancelIcon/></Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </Form>
  );
}
