import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryQuestionnaireNames from "./useQueryQuestionnaireNames";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import SearchBox from "../../common/SearchBox";

export default function QuestionnaireSearchBox(/*props*/) {

  const {data: results, loading: questionnairesLoading, errors: questionnairesErrors} = useQueryQuestionnaireNames();

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionnaireSearchBox");
  const history = useHistory();

  const useQueryOptions = () => {
    return {
      options: results ? results.nodes : [],
      loading: questionnairesLoading,
      errors: questionnairesErrors
    }
  }

  const onSelectOption = (option) => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.QUESTIONNAIRE, {questionnaireId: option._id}));
  }

  const errorMsg = t("reporting:questionnaires_search_filter_error");
  const emptyMsg =  t("reporting:questionnaires_search_filter_empty");
  const placeholder = t("reporting:questionnaires_search_filter_placeholder");

  return (
    <SearchBox
      className = {"QuestionnaireSearchBox"}
      useQueryOptions = {useQueryOptions}
      onSelectOption = {onSelectOption}
      errorMsg = {errorMsg}
      emptyMsg = {emptyMsg}
      placeholder = {placeholder}
      labelKey = {"name"}
    />
  );
}
