import React from "react";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../common/modals/useFeedbackModal";
import ActionLink from "../../common/widgets/ActionLink";

export default function ResumeDeleteLink(props) {

  const {onDeleteResume, loading, errors, onCompleted, disabled, children} = props;

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(onDeleteResume);
  }

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "ResumeDeleteLink");

  const successMsg = <p>{t("common:candidates_resume_delete_success_msg")}</p>;
  const errorMsg = useServerErrorFormatter(errors);
  const confirmationMsg = <p>{t("common:candidates_resume_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal(successMsg, errorMsg, onCompleted, confirmationMsg);

  return (
    <>
      {FeedbackModal}
      <ActionLink onClick={onClickDelete} loading={loading} disabled={disabled}>{children}</ActionLink>
    </>
  );
}
