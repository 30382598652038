import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import ActionButtonWithIcon from "../../common/widgets/ActionButtonWithIcon";

/**
 * ActionButton with an X icon at the end of text.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionButtonWithIconCancel(props) {
  return <ActionButtonWithIcon Icon={CloseIcon} {...props}/>
};
