import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import useMutationPoolJobCreate from "./useMutationPoolJobCreate";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import ActionButtonWithIconGo from "../../../common/widgets/ActionButtonWithIconGo";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import StringUtils from "../../../../utils/StringUtils";

export default function PoolJobCreateForm(props) {

  const {onAbort, onSuccess, talentPoolId} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading);

  const {mutate, loading: mutationLoading, errors: mutationErrors} = useMutationPoolJobCreate();

  const errorMsg = useServerErrorFormatter(mutationErrors);
  const {launch, FeedbackModal} = useFeedbackModal(null, errorMsg, onSuccess);

  const [name, setTitle] = React.useState("");

  const createPoolJob = () => {
    return mutate({
      variables: {
        name: name.trim(),
        talentPoolId
      }
    });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    launch(createPoolJob);
  }

  const canSubmit = () => {
    return !StringUtils.isNullOrEmpty(name);
  }

  return (
    <>
      {FeedbackModal}
      <div className="PoolJobCreateForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"} autoComplete={"off"}>
          <Row>
            <Col>
              <h1>{t("market:pool_jobs_create_title")}</h1>
            </Col>
          </Row>
          <Row>
            <Col className={"form-label form-required"}>
              {t("market:pool_jobs_create_name_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                name="name"
                type="text"
                value={name}
                onChange={event => setTitle(event.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButtonWithIconGo loading={mutationLoading} type={"submit"} disabled={!canSubmit()}>
                {t("market:pool_jobs_create_submit_action")}
              </ActionButtonWithIconGo>
              <ActionButtonWithIconCancel onClick={onAbort}>{t("market:pool_jobs_create_cancel_action")}</ActionButtonWithIconCancel>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
