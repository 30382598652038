import React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import StringUtils from "../../utils/StringUtils";
import "./SignInForm.scss";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import useSignIn from "../authentication-no-ui/useSignIn";
import * as ROUTES from "../../constants/routes";
import useReferrer from "../infra-no-ui/navigation/useReferrer";
import {Redirect} from "react-router-dom";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionButtonWithIconGo from "../common/widgets/ActionButtonWithIconGo";
import useFeedbackModal from "../common/modals/useFeedbackModal";

export default function SignInForm() {

  const sendSignInRequest = () => {
    return signIn(email.trim(), password);
  }

  const onSubmit = event => {
    event.preventDefault();
    launch(sendSignInRequest);
  }

  const canSubmit = () => {

    let e = "";

    if (email === "") {
      e = t("authentication-ui:sign_in_email_required");
    }
    if (password === "") {
      e = t("authentication-ui:sign_in_password_required");
    }

    return e === "";
  };

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  const authUser = useAuthUser();
  const {referrer} = useReferrer(ROUTES.HOME);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const {signIn, loading, error, ready: signInReady} = useSignIn();
  const {launch, FeedbackModal} = useFeedbackModal(undefined, error);

  useShowLoader(tLoading || !signInReady, "SignInForm");

  // RENDER

  // If already logged, or when rendering after sign in, do not display this page, redirect to referrer or HOME
  if (authUser.authenticated) {
    return <Redirect to={referrer} />;
  }

  return (
    <>
      {FeedbackModal}
      <div className="SignInForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"}>
          <Row>
            <Col>
              <FormControl
                name="email"
                placeholder={t("authentication-ui:sign_in_email_placeholder")}
                value={StringUtils.nullToEmpty(email)}
                onChange={event => setEmail(event.target.value)}
                type="email"
                autoComplete="email"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                name="password"
                type="password"
                placeholder={t("authentication-ui:sign_in_password_placeholder")}
                value={StringUtils.nullToEmpty(password)}
                onChange={event => setPassword(event.target.value)}
                autoComplete="current-password"
              />
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButtonWithIconGo
                loading={loading}
                type="submit"
                disabled={!canSubmit()}
                className="submit-button"
              >
              {t("authentication-ui:sign_in_sign_in_button")}
              </ActionButtonWithIconGo>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
