import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useContentModal from "../../../common/modals/useContentModal";
import ClientCreateForm from "../mutate/ClientCreateForm";
import ClientSearchBox from "../search/ClientSearchBox";
import LastViewedClients from "./LastViewedClients";
import ActionLink from "../../../common/widgets/ActionLink";
import {useHistory} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import "./ClientsBlock.scss";
import ClientsStatsBlock from "./ClientsStatsBlock";

function ClientsBlock(/*props*/) {

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientsBlock");
  // Modal to show client creation form
  const {ContentModal: ClientCreateModal, show: showClientCreateModal, hide: hideClientCreateModal} = useContentModal();
  const history = useHistory();

  const onClickCreate = () => {
    showClientCreateModal(clientCreateForm);
  }

  const onClientCreated = (client) => {
    hideClientCreateModal();
    history.push(RouterUtils.injectParamsInRoute(ROUTES.CLIENT, {clientId: client._id}));
  };

  const clientCreateForm = <ClientCreateForm onAbort={hideClientCreateModal} onSuccess={onClientCreated}/>;

  return (
    <>
      {ClientCreateModal}
      <div className="ClientsBlock">
        <Row className="search-and-stats-block">
          <Col>
            <Row className={"search-block-row"}>
              <Col>
                <Row>
                  <Col>
                    <ClientSearchBox autofocus/>
                  </Col>
                </Row>
                <Row className={"create-link-row"}>
                  <Col>
                    <ActionLink onClick={onClickCreate}>{t("reporting:clients_create_action")}</ActionLink>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="list-row">
              <Col>
                <LastViewedClients/>
              </Col>
            </Row>
          </Col>
          <Col className={"stats-col"}>
            <ClientsStatsBlock/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ClientsBlock;
