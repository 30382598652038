import React from "react";
import SplashScreen from "./SplashScreen";
import Loader from "../common/loading-widgets/Loader";
import AppRouter from "./AppRouter";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";
import UntranslatedContentError from "../common/error-boundaries/UntranslatedContentError";

// Main App
export default function App() {

  return (
    <ErrorBoundary component={<UntranslatedContentError/>}>
      <Loader icon={<SplashScreen/>} minDelay={3000}>
        <AppRouter/>
      </Loader>
    </ErrorBoundary>
  );
}
