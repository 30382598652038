import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import "./SignInPage.scss";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SignInForm from "./SignInForm";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Page from "../common/page/Page";

export default function SignInPage() {

  const {t, loading: tLoading} = useTranslationAuthenticationUi();

  useShowLoader(tLoading, "SignInPage");

  // RENDER
  return (
    <Page className="SignInPage">
      <Page.Title loading={tLoading} text={t("authentication-ui:sign_in_title")}/>
      <Page.Content>
        <Row>
          <Col xs="12" sm="6">
            <SignInBlock />
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
}

function SignInBlock() {

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "SignInBlock");

  // RENDER

  return (
    <Card className="SignInBlock">
      <Card.Body className="spaced-children">
        <Row>
          <Col>
            <h4>{t("authentication-ui:sign_in_title")}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <SignInForm />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ForgotPasswordLink">
              <Link to={ROUTES.RESET_PASSWORD}>{t("authentication-ui:sign_in_forgot_password_link")}</Link>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
