import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import {useHistory} from "react-router-dom";
import "./QuestionnaireSearchResult.scss";
import ActionButton from "../../../common/widgets/ActionButton";
import QuestionByQuestionnaireCount from "./QuestionByQuestionnaireCount";

export default function QuestionnaireSearchResult(props) {

  const {item: questionnaire} = props;

  const history = useHistory();

  function onClickQuestionnaire(event, questionnaireId) {
    event.preventDefault();
    history.push(RouterUtils.injectParamsInRoute(ROUTES.QUESTIONNAIRE, {questionnaireId}))
  }

  return (
    <ActionButton as={"div"} variant="outline-primary" onClick={event => onClickQuestionnaire(event, questionnaire._id)} className={"QuestionnaireSearchResult"}>
      <Row>
        <Col className={"name-col"}>
          {questionnaire.name}
        </Col>
        <Col className={"question-col"}>
          <QuestionByQuestionnaireCount questionnaireId={questionnaire._id}/>
        </Col>
      </Row>
    </ActionButton>
  );
};
