import React from 'react';
import "./SplashScreen.scss";
import logo from "../../img/logo-exotalent.svg";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function SplashScreen() {

  return (
    <div className="SplashScreen">
      <Row className="logo-row">
        <Col>
          <Image src={logo} className="logo"/>
        </Col>
      </Row>
    </div>
  );
}
