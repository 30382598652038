import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as ROUTES from "../../../../constants/routes";
import {useHistory} from "react-router-dom";
import ClientDeleteButton from "../mutate/ClientDeleteButton";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./ClientDetails.scss";
import ClientInlineEditInputTypeahead from "../mutate/ClientInlineEditInputTypeahead";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQuestionnaireTypeaheadOptions from "./useQuestionnaireTypeaheadOptions";

export default function ClientDetails(props) {

  const {client} = props;
  const history = useHistory();

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientDetails");

  // Prepare the options for the questionnaire select box
  const {loading, errors, ...typeaheadProps} = useQuestionnaireTypeaheadOptions(client.questionnaire);

  const onDeleteCompleted = () => {
    history.push(ROUTES.CLIENTS);
  }

  return (
    <div className="ClientDetails">
      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:clients_details_questionnaire_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <WaitForData loading={loading} errors={errors} onLoaded={() =>
                <ClientInlineEditInputTypeahead
                  client={client}
                  property={"questionnaireId"}
                  {...typeaheadProps}
                />
              }/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:details_other_actions_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <ClientDeleteButton clientId={client._id} onCompleted={onDeleteCompleted}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>
    </div>
  );
};
