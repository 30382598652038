import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import TypeUtils from "../../../../utils/TypeUtils";
import CandidateAnswer from "./CandidateAnswer";
import "./CandidateQuestionnaire.scss";
import QuestionnaireStats from "../view/QuestionnaireStats";

export default function CandidateQuestionnaire(props) {
  const {candidate} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "CandidateQuestionnaire");

  const candidateAnswers = TypeUtils.ensureArray(candidate.answers);

  return (
    <div className="CandidateQuestionnaire">
      <Row>
        <Col>
          <h2>{t("reporting:candidates_details_questionnaire_title")}</h2>
        </Col>
        <Col className={"stats-col"}>
          <QuestionnaireStats answers={candidateAnswers}/>
        </Col>
      </Row>
      {TypeUtils.ensureArray(candidateAnswers).map(candidateAnswer =>
      <Row key={candidateAnswer._id}>
        <Col>
          <CandidateAnswer candidate={candidate} question={candidateAnswer.question} answer={candidateAnswer.answer}/>
        </Col>
      </Row>
      )}
    </div>
  );
};
