import React from "react";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationTalentPoolDelete from "./useMutationTalentPoolDelete";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import ActionIcon from "../../../common/widgets/ActionIcon";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import useTranslationMarket from "../../useTranslationMarket";

export default function TalentPoolDeleteButton(props) {

  const {talentPoolId, onCompleted} = props;

  const deleteTalentPool = () => {
    return mutate({
      variables: {
        id: talentPoolId,
      },
    });
  }

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deleteTalentPool);
  }

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolDeleteButton");

  const {mutate, loading: deleteLoading, errors: deleteErrors} = useMutationTalentPoolDelete();

  // Page refreshes rapidly after deletion, the success msg just creates a flicker, so don't display feedback
  //const successMsg = <p>{t("market:pool_jobs_delete_success_msg")}</p>;
  const successMsg = undefined;
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmationMsg = <p>{t("market:talent_pools_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal(successMsg, errorMsg, onCompleted, confirmationMsg);

  return (
    <>
      {FeedbackModal}
      <ActionIcon onClick={onClickDelete} loading={deleteLoading} icon={DeleteIcon}>{t("market:talent_pools_details_delete_action")}</ActionIcon>
    </>
  );
}
