import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import ActionIcon from "../../../common/widgets/ActionIcon";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import useMutationQuestionnaireRemoveQuestion from "../../questionnaires/mutate/useMutationQuestionnaireRemoveQuestion";

export default function QuestionDeleteButton(props) {

  const {questionId, questionnaireId, onCompleted} = props;

  const deleteQuestion = () => {
    return mutate({
      variables: {
        questionId,
        questionnaireId
      },
    });
  }

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deleteQuestion);
  }

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionDeleteButton");

  const {mutate, loading: deleteLoading, errors: deleteErrors} = useMutationQuestionnaireRemoveQuestion();

  const successMsg = <p>{t("reporting:questions_delete_success_msg")}</p>;
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmationMsg = <p>{t("reporting:questions_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal(successMsg, errorMsg, onCompleted, confirmationMsg);

  return (
    <>
      {FeedbackModal}
      <ActionIcon onClick={onClickDelete} loading={deleteLoading} icon={DeleteIcon}>{t("reporting:questions_details_delete_action")}</ActionIcon>
    </>
  );
}
