import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as ROUTES from "../../../../constants/routes";
import {useHistory} from "react-router-dom";
import QuestionnaireDeleteButton from "../mutate/QuestionnaireDeleteButton";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./QuestionnaireDetails.scss";

export default function QuestionnaireDetails(props) {

  const {questionnaire} = props;
  const history = useHistory();

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionnaireDetails");

  const onDeleteCompleted = () => {
    history.push(ROUTES.QUESTIONNAIRES);
  }

  return (
    <div className="QuestionnaireDetails">
      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:details_other_actions_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <QuestionnaireDeleteButton questionnaireId={questionnaire._id} onCompleted={onDeleteCompleted}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>
    </div>
  );
};
