import React from "react";
import ErrorIcon from '@material-ui/icons/ReportProblemOutlined';
import "./LoadingError.scss";
import StringUtils from "../../../utils/StringUtils";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "./useShowLoader";

export default function LoadingError(props) {

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "LoadingError");

  // RENDER

  return (
    <div className={"LoadingError " + StringUtils.nullToEmpty(props.className)}>
      <div>
        <ErrorIcon className="error-icon"/>
      </div>
      <div>
        {t("common:loading_data_error")}
      </div>
    </div>
  );
}
