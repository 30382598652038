import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./JobCategoryBlock.scss";
import JobCategoryInlineEditName from "../mutate/JobCategoryInlineEditName";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import PoolCandidateList from "../../candidates/view/PoolCandidateList";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import {useHistory} from "react-router";

export default function JobCategoryBlock(props) {

  const {jobCategory, talentPoolId} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "JobCategoryBlock")

  const history = useHistory();

  const goBack = () => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.POOL_JOB, {talentPoolId, poolJobId: jobCategory.poolJobId}));
  }

  return (
    <div className="JobCategoryBlock">
      <Row>
        <Col className={"job-category-details"}>
          <Row className={"title-row"}>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
              </div>
              <JobCategoryInlineEditName jobCategory={jobCategory} viewAs="h1"/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"candidates-list"}>
          <Row>
            <Col>
              <h2>{t("market:job_categories_candidates_title")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <PoolCandidateList jobCategoryId={jobCategory._id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
