import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionDiv from "../../../common/widgets/ActionDiv";
import JobCategoryDeleteButton from "../mutate/JobCategoryDeleteButton";
import "./JobCategoryPreview.scss";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";

export default function JobCategoryPreview(props) {

  const {jobCategory, onClick} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "JobCategoryPreview");

  return (
    <div className="JobCategoryPreview">
      <Row className={"delete-row"}>
        <Col className={"delete-col"}>
          <JobCategoryDeleteButton jobCategoryId={jobCategory._id}/>
        </Col>
      </Row>
      <Row className={"content-row"}>
        <Col className={"content-col"}>
          <Row className={"name-row"}>
            <Col className={"name-col"}>
              <ActionDiv onClick={() => onClick(jobCategory._id)} className={"name"}>
                {jobCategory.name}
              </ActionDiv>
            </Col>
          </Row>
          <Row className={"candidate-row"}>
            <Col className={"candidate-col"}>
              {t("market:job_categories_preview_candidate_count", {count: StringUtils.nullToZero(jobCategory.candidateCount)})}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
