import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DragHandleIcon from "@material-ui/icons/OpenWith";
import QuestionInlineEditInputText from "../../questions/mutate/QuestionInlineEditInputText";
import QuestionDeleteButton from "../../questions/mutate/QuestionDeleteButton";
import "./QuestionDetails.scss";

export default function QuestionDetails(props) {
  const {question, questionnaireId, onCompleted, isDraggable, isDragged} = props;

  // If no question is provided, we want to create one
  const isCreation = !question;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading);

  // If in creation mode, cancelling the edition of the text input box removes the whole QuestionDetails component;
  // otherwise cancelling the edition of the text input box acts normally (it brings back the old value)
  const onCancel = isCreation ? onCompleted : undefined;

  const isDraggedClassName = isDragged ? "is-dragged" : "";

  if (isDraggable) {
    return (
      <div className={"QuestionDetails " + isDraggedClassName}>
        <Row>
          <Col>
            {question.text}
          </Col>
          <Col className={"drag-handle-col"}>
            <DragHandleIcon/>
          </Col>
        </Row>
      </div>
    )
  } else {
    return (
      <div className={"QuestionDetails"}>
        <Row>
          <Col>
            <QuestionInlineEditInputText
              question={question}
              property={"text"}
              placeholder={t("reporting:questions_details_text_placeholder")}
              questionnaireId={questionnaireId}
              onCompleted={onCompleted}
              editOnly={isCreation}
              onCancel={onCancel}
            />
          </Col>
          {!isCreation &&
          <>
            {/*
            <Col className={"mandatory-col"}>
              <QuestionInlineEditInputBoolean question={question} property={"mandatory"}
                                              placeholder={t("reporting:questions_details_mandatory_placeholder")}/>
            </Col>
            */}
            <Col className={"delete-col"}>
              <QuestionDeleteButton questionId={question._id} questionnaireId={questionnaireId}/>
            </Col>
          </>
          }
        </Row>
      </div>
    );
  }
};
