import React from "react";
import QuestionnaireInlineEditName from "../mutate/QuestionnaireInlineEditName";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./QuestionnaireBlock.scss";
import QuestionList from "./QuestionList";
import ActionLink from "../../../common/widgets/ActionLink";
import DetailsCollapsibleSection from "../../common/DetailsCollapsibleSection";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import QuestionnaireDetails from "./QuestionnaireDetails";

export default function QuestionnaireBlock(props) {

  const {questionnaire} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientBlock");

  const [showDetails, setShowDetails] = React.useState(false);

  const onClickDetails = event => {
    event.preventDefault();
    setShowDetails(prevState => !prevState);
  }

  const DetailsIcon = showDetails ? CollapseIcon : ExpandIcon;

  return (
    <>
      <div className="QuestionnaireBlock">
        <Row className={"title-row"}>
          <Col>
            <QuestionnaireInlineEditName questionnaire={questionnaire} viewAs="h1"/>
          </Col>
          <Col className={"details-col"}>
            <ActionLink onClick={onClickDetails}>{t("reporting:questionnaires_details_view_action")} <DetailsIcon/></ActionLink>
          </Col>
        </Row>
        <DetailsCollapsibleSection isOpened={showDetails}>
          <QuestionnaireDetails questionnaire={questionnaire}/>
        </DetailsCollapsibleSection>
        <Row>
          <Col>
            <QuestionList questionnaire={questionnaire}/>
          </Col>
        </Row>
      </div>
    </>
  );
};
