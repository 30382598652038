import React from "react";
import usePublicTalentPoolUrl from "./usePublicTalentPoolUrl";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./TalentPoolPublicLink.scss";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CopyIcon from "@material-ui/icons/Assignment";

export default function TalentPoolPublicLink(props) {

  const {talentPool} = props;
  const publicReportUrl = usePublicTalentPoolUrl(talentPool._id);
  const published = talentPool.published;

  const [copied, setCopied] = React.useState(false);

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolPublicLink");

  return (
    <div className="TalentPoolPublicLink">
      {/* When target is _blank, add rel for security reasons */}
      {published && <a disabled={!published} href={publicReportUrl} target={"_blank"} rel="noopener noreferrer">{publicReportUrl}</a>}
      {!published && <span className={"unpublished-value"}>{publicReportUrl + " "}<span className={"unpublished-label"}>{t("market:talent_pools_details_unpublished_label")}</span></span>}
      <CopyToClipboard text={publicReportUrl} onCopy={() => setCopied(true)}><span className={"copy-action"}>
        <CopyIcon titleAccess={t("market:talent_pools_details_clipboard_copy_action")}/>
      </span></CopyToClipboard>
      <span className={"copied-label" + (copied ? " active" : "")}>{t("market:talent_pools_details_clipboard_copied")}</span>
    </div>
  );
};
